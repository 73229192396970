import React, { useState, useEffect, useRef, useCallback } from 'react';
import { List, Typography, Card, Button, message } from 'antd';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { DateTime } from 'luxon';
import styled, { keyframes, css } from 'styled-components';
import { SoundOutlined, AudioMutedOutlined } from '@ant-design/icons';
import useSound from 'use-sound';


const { Text } = Typography;

interface Message {
    id: string;
    text: string;
    timestamp: number;
}

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const MessageBubble = styled.div<{ isRecent: boolean }>`
  background-color: #e6f7ff;
  border-radius: 18px;
  padding: 10px 15px;
  margin-bottom: 10px;
  max-width: 70%;
  word-wrap: break-word;
  ${props => props.isRecent && css`
    animation: ${blink} 1s ease-in-out infinite;
  `}
`;

const TimeStamp = styled(Text)`
  font-size: 12px;
  color: #8c8c8c;
  display: block;
  margin-bottom: 5px;
`;

const MessageViewer: React.FC = () => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [isMuted, setIsMuted] = useState(false);

    const audio = new Audio('https://markkiee.nl/danger.mp3');
    const audionot = new Audio('https://markkiee.nl/not.mp3');

    const playSound = () => {
        audio.volume = 0.1;
        audio.play();
    }

    useEffect(() => {
        const db = getDatabase();
        const messagesRef = ref(db, 'messages');

        const handleData = (snapshot: any) => {
            if (snapshot.exists()) {
                const messagesData = snapshot.val();
                const messageArray: Message[] = Object.entries(messagesData).map(([id, value]: [string, any]) => ({
                    id,
                    text: value.text,
                    timestamp: value.timestamp,
                }));

                // Sort messages by timestamp in descending order (newest first)
                messageArray.sort((a, b) => b.timestamp - a.timestamp);
                setMessages(messageArray);

                // Check if the newest message contains "tegenhunt" and play siren
                if (messageArray.length > 0 && messageArray[0].text.toLowerCase().includes("tegenhunt")) {
                    audio.play();
                    console.log("'tegenhunt' detected in new message");
                }
                else {
                    audionot.play();
                }
            }
        };

        onValue(messagesRef, handleData);

        return () => {
            off(messagesRef, 'value', handleData);
        };
    }, []);

    const isMessageRecent = (timestamp: number) => {
        const messageTime = DateTime.fromMillis(timestamp);
        const now = DateTime.now();
        const diff = now.diff(messageTime, 'minutes').minutes;
        return diff <= 2; // Message is recent if it's less than or equal to 2 minutes old
    };

    const renderMessage = (message: Message, index: number) => {
        const isRecent = index === 0 && isMessageRecent(message.timestamp);
        return (
            <MessageBubble isRecent={isRecent}>
                <TimeStamp>{DateTime.fromMillis(message.timestamp).toFormat('dd-MM-yyyy HH:mm:ss')}</TimeStamp>
                <Text>{message.text}</Text>
            </MessageBubble>
        );
    };

    return (
        <Card
            style={{ maxWidth: 600, margin: '20px auto' }}
            extra={
                <>
                </>
            }
        >
            <Button onClick={playSound}>Test</Button>
            <Typography.Title level={2}>Telegram berichten</Typography.Title>
            <List
                dataSource={messages}
                renderItem={(message, index) => (
                    <List.Item style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        {renderMessage(message, index)}
                    </List.Item>
                )}
            />
        </Card>
    );
};

export default MessageViewer;