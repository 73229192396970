import React, { useState, useEffect } from 'react';
import JotihuntAssignments from './JotihuntAssignments';
import Login from './Login';
import MessageViewer from './MessageViewer';
import { Toaster } from 'react-hot-toast';
import { auth, logOut } from './firebaseAuth';
import { onAuthStateChanged } from 'firebase/auth';
import { Layout, Menu, Button, message } from 'antd';
import { HomeOutlined, MessageOutlined, MenuOutlined, LogoutOutlined, EnvironmentOutlined, KeyOutlined } from '@ant-design/icons';
import { useMediaQuery } from '@material-ui/core';
import MapPage from './MapPage';
import LocationKeyManagement from './LocationSharing';

const { Content, Sider } = Layout;

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState('map');
  const [collapsed, setCollapsed] = useState(true);
  const isMobile = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setCollapsed(isMobile);
  }, [isMobile]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const renderContent = () => {
    if (!isAuthenticated) {
      return <Login onLogin={() => setIsAuthenticated(true)} />;
    }

    switch (currentPage) {
      case 'assignments':
        return <JotihuntAssignments />;
      case 'messages':
        return <MessageViewer />;
      case 'map':
        return <MapPage />;
      case 'location-key':
        return <LocationKeyManagement />;
      default:
        return <MapPage />;
    }
  };

  const toggleMenu = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = async () => {
    try {
      await logOut();
      setIsAuthenticated(false);
      message.success('Logged out successfully');
    } catch (error) {
      message.error('Logout failed: ' + (error as Error).message);
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {isAuthenticated && (
        <>
          {isMobile && (
            <Button
              type="primary"
              icon={<MenuOutlined />}
              onClick={toggleMenu}
              style={{ position: 'fixed', top: 16, left: 16, zIndex: 1 }}
            />
          )}
          <Sider
            collapsible={!isMobile}
            collapsed={collapsed}
            onCollapse={setCollapsed}
            collapsedWidth={isMobile ? 0 : 50}
            width={200}
            style={{
              background: '#fff',
              position: isMobile ? 'fixed' : 'relative',
              height: '100%',
              zIndex: 2,
              left: collapsed && isMobile ? -200 : 0,
              transition: 'all 0.2s',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Menu
                mode="inline"
                defaultSelectedKeys={['map']}
                onSelect={({ key }) => {
                  if (key !== 'logout') {
                    setCurrentPage(key as string);
                    if (isMobile) setCollapsed(true);
                  }
                }}
                style={{ flexGrow: 1, borderRight: 0 }}
              >
                <Menu.Item key="map" icon={<EnvironmentOutlined />}>
                  Map
                </Menu.Item>
                <Menu.Item key="assignments" icon={<HomeOutlined />}>
                  Assignments
                </Menu.Item>
                <Menu.Item key="messages" icon={<MessageOutlined />}>
                  Messages
                </Menu.Item>
                <Menu.Item key="location-key" icon={<KeyOutlined />}>
                  Location Key
                </Menu.Item>
              </Menu>
              <Menu
                mode="inline"
                selectable={false}
                onClick={({ key }) => {
                  if (key === 'logout') {
                    handleLogout();
                  }
                }}
                style={{ borderRight: 0 }}
              >
                <Menu.Item key="logout" icon={<LogoutOutlined />}>
                  Logout
                </Menu.Item>
              </Menu>
            </div>
          </Sider>
        </>
      )}
      <Layout>
        <Content style={{ margin: isMobile ? '48px 0 0 0' : 0 }}>
          {renderContent()}
        </Content>
      </Layout>
      <div><Toaster position="top-right" /></div>
    </Layout>
  );
};

export default App;