import React, { useState, useEffect } from 'react';
import { Card, Typography, Button, Input, message, Alert, Form } from 'antd';
import { CopyOutlined, ReloadOutlined } from '@ant-design/icons';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, set, get } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';

const { Title, Text } = Typography;

const LocationKeyManagement = () => {
    const [personalKey, setPersonalKey] = useState<string>('');
    const [displayName, setDisplayName] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const auth = getAuth();
    const db = getDatabase();

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        if (!auth.currentUser) return;

        try {
            const keyRef = ref(db, `userKeys/${auth.currentUser.uid}`);
            const snapshot = await get(keyRef);

            if (snapshot.exists()) {
                const userData = snapshot.val();
                setPersonalKey(userData.key);
                setDisplayName(userData.name || '');
            } else {
                generateNewKey();
            }
        } catch (error) {
            message.error('Kon gebruikersgegevens niet ophalen');
        } finally {
            setLoading(false);
        }
    };

    const generateNewKey = async () => {
        if (!auth.currentUser) return;

        try {
            setLoading(true);
            const newKey = uuidv4().slice(0, 8);
            const keyRef = ref(db, `userKeys/${auth.currentUser.uid}`);

            await set(keyRef, {
                key: newKey,
                name: displayName,
                email: auth.currentUser.email,
                createdAt: new Date().toISOString()
            });

            setPersonalKey(newKey);
            message.success('Nieuwe sleutel succesvol gegenereerd');
        } catch (error) {
            message.error('Kon geen nieuwe sleutel genereren');
        } finally {
            setLoading(false);
        }
    };

    const updateDisplayName = async () => {
        if (!auth.currentUser) return;

        try {
            setLoading(true);
            const keyRef = ref(db, `userKeys/${auth.currentUser.uid}`);

            await set(keyRef, {
                key: personalKey,
                name: displayName,
                email: auth.currentUser.email,
                updatedAt: new Date().toISOString()
            });

            message.success('Weergavenaam succesvol bijgewerkt');
        } catch (error) {
            message.error('Kon weergavenaam niet bijwerken');
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(personalKey)
            .then(() => message.success('Sleutel gekopieerd naar klembord'))
            .catch(() => message.error('Kon sleutel niet kopiëren'));
    };

    return (
        <div className="w-full max-w-2xl mx-auto p-4">
            <Card loading={loading}>
                <Title level={2}>Locatie Delen Instellingen</Title>

                {/* Display Name Section */}
                <div className="mb-6">
                    <Text strong className="block mb-2">Weergavenaam</Text>
                    <Form.Item
                        extra="Deze naam zal zichtbaar zijn op de kaart voor andere gebruikers"
                        className="mb-4"
                    >
                        <div className="flex gap-2">
                            <Input
                                value={displayName}
                                onChange={(e) => setDisplayName(e.target.value)}
                                placeholder="Voer je weergavenaam in"
                                className="flex-grow"
                            />
                            <Button
                                onClick={updateDisplayName}
                                type="primary"
                            >
                                Naam Opslaan
                            </Button>
                        </div>
                    </Form.Item>
                </div>

                {/* Key Management Section */}
                <div className="mb-6">
                    <Text strong className="block mb-2">Je Locatie Deel-sleutel</Text>
                    <Text className="block mb-4">
                        Gebruik deze sleutel bij het instellen van de Telegram bot om je locatie te delen.
                    </Text>

                    <div className="bg-gray-50 p-4 rounded-lg mb-4">
                        <div className="flex items-center gap-4">
                            <Input
                                value={personalKey}
                                readOnly
                                size="large"
                                className="font-mono"
                            />
                            <Button
                                icon={<CopyOutlined />}
                                onClick={copyToClipboard}
                                type="primary"
                            >
                                Kopiëren
                            </Button>
                        </div>
                    </div>

                    <div className="mb-4">
                        <Button
                            icon={<ReloadOutlined />}
                            onClick={generateNewKey}
                            type="default"
                        >
                            Nieuwe Sleutel Genereren
                        </Button>
                    </div>
                </div>

                <Alert
                    message="Belangrijk"
                    description={
                        <ul className="list-disc pl-4">
                            <li>Je weergavenaam is zichtbaar voor andere gebruikers op de kaart</li>
                            <li>Houd je sleutel privé en veilig</li>
                            <li>Je kunt op elk moment een nieuwe sleutel genereren</li>
                            <li>Wanneer je een nieuwe sleutel genereert, wordt de oude ongeldig</li>
                            <li>Deel je locatie met de Telegram bot met behulp van deze sleutel</li>
                        </ul>
                    }
                    type="info"
                    showIcon
                />
            </Card>
        </div>
    );
};

export default LocationKeyManagement;