import React, { useState } from 'react';
import { Form, Input, Button, message, Card, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { signIn } from './firebaseAuth';
import styled from 'styled-components';

const { Title } = Typography;

const StyledCard = styled(Card)`
  max-width: 400px;
  margin: 100px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const StyledForm = styled(Form)`
  padding: 24px;
`;

const StyledTitle = styled(Title)`
  text-align: center;
  margin-bottom: 24px !important;
`;

interface LoginFormValues {
    email: string;
    password: string;
}

const Login: React.FC<{ onLogin: () => void }> = ({ onLogin }) => {
    const [loading, setLoading] = useState(false);

    const onFinish = async (values: any) => {
        const { email, password } = values as LoginFormValues;
        setLoading(true);
        try {
            await signIn(email, password);
            message.success('Login successful');
            onLogin();
        } catch (error) {
            message.error('Login failed: ' + (error as Error).message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <StyledCard>
            <StyledForm
                name="login"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                <StyledTitle level={2}>Jotihunt Login</StyledTitle>
                <Form.Item
                    name="email"
                    rules={[
                        { required: true, message: 'Please input your email!' },
                        { type: 'email', message: 'Please enter a valid email!' }
                    ]}
                >
                    <Input
                        prefix={<UserOutlined />}
                        placeholder="Email"
                        size="large"
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password
                        prefix={<LockOutlined />}
                        placeholder="Password"
                        size="large"
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        style={{ width: '100%' }}
                        size="large"
                    >
                        Log in
                    </Button>
                </Form.Item>
            </StyledForm>
        </StyledCard>
    );
};

export default Login;