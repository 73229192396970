import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { getDatabase, ref, onValue, set, off } from 'firebase/database';
import { Spin, message } from 'antd';
import axios from 'axios';

interface Subscription {
    id: number;
    name: string;
    accomodation: string;
    street: string;
    housenumber: number;
    housenumber_addition: string | null;
    postcode: string;
    city: string;
    lat: string;
    long: string;
    photo_assignment_points: number;
}

const MapComponent = () => {
    const mapContainer = useRef<HTMLDivElement>(null);
    const map = useRef<mapboxgl.Map | null>(null);
    const markersRef = useRef<{ [key: string]: mapboxgl.Marker }>({});
    const [loading, setLoading] = useState(true);

    const fetchSubscriptions = async () => {
        try {
            const response = await axios.get('https://jotihunt.nl/api/2.0/subscriptions');
            const subscriptions = response.data.data;

            const db = getDatabase();
            const subscriptionsRef = ref(db, 'subscriptions');
            await set(subscriptionsRef, subscriptions);

        } catch (error) {
            console.error('Error fetching subscriptions:', error);
        }
    };

    const openGoogleMapsNavigation = (destination: { lat: string; long: string; name: string }) => {
        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${destination.lat},${destination.long}&travelmode=driving`;
        window.open(googleMapsUrl, '_blank');
    };

    const handleButtonClick = async (e: MouseEvent, id: string, action: 'primary' | 'secondary', subscriptionData?: Subscription) => {
        e.preventDefault();
        e.stopPropagation();

        if (action === 'primary' && subscriptionData) {
            try {
                openGoogleMapsNavigation({
                    lat: subscriptionData.lat,
                    long: subscriptionData.long,
                    name: subscriptionData.name
                });
                message.success('Opening Google Maps navigation');
            } catch (error) {
                console.error('Error opening navigation:', error);
                message.error('Could not open navigation');
            }
        }
    };

    const createPopupContent = (type: 'location' | 'vehicle', data: any) => {
        const container = document.createElement('div');
        container.className = 'popup-content';

        if (type === 'location') {
            const { id, name, street, housenumber, housenumber_addition, postcode, city, photo_assignment_points } = data;
            container.innerHTML = `
                <div class="popup-content">
                    <h3 style="font-size: 16px; font-weight: bold; margin-bottom: 8px;">${name}</h3>
                    <p style="margin: 4px 0;">${street} ${housenumber}${housenumber_addition || ''}</p>
                    <p style="margin: 4px 0;">${postcode} ${city}</p>
                    <p style="margin: 4px 0;">Foto opdrachten: ${photo_assignment_points}: punten</p>
                    <div style="display: flex; gap: 8px; margin-top: 12px;">
                        <button
                            class="popup-button primary"
                            style="padding: 6px 12px; background: #1677ff; color: white; border: none; border-radius: 4px; cursor: pointer;"
                            data-id="${id}"
                            data-action="primary"
                        >
                            Navigeer
                        </button>
                        <button
                            class="popup-button secondary"
                            style="padding: 6px 12px; background: #52c41a; color: white; border: none; border-radius: 4px; cursor: pointer;"
                            data-id="${id}"
                            data-action="secondary"
                        >
                            Details
                        </button>
                    </div>
                </div>
            `;
        } else {
            const { userId, name, speed, lastUpdateTime } = data;
            container.innerHTML = `
                <div class="popup-content">
                    <h3 style="font-size: 16px; font-weight: bold; margin-bottom: 8px;">${name}</h3>
                    <p style="margin: 4px 0;">Snelheid: ${(speed * 3.6).toFixed(1)} km/h</p>
                    <p style="margin: 4px 0;">Laatste update: ${lastUpdateTime}</p>
                    <div style="display: flex; gap: 8px; margin-top: 12px;">
                        <button
                            class="popup-button primary"
                            style="padding: 6px 12px; background: #1677ff; color: white; border: none; border-radius: 4px; cursor: pointer;"
                            data-id="${userId}"
                            data-action="primary"
                        >
                            Volgen
                        </button>
                        <button
                            class="popup-button secondary"
                            style="padding: 6px 12px; background: #52c41a; color: white; border: none; border-radius: 4px; cursor: pointer;"
                            data-id="${userId}"
                            data-action="secondary"
                        >
                            Info
                        </button>
                    </div>
                </div>
            `;
        }

        // Add click event listeners to buttons
        const buttons = container.getElementsByTagName('button');
        Array.from(buttons).forEach(button => {
            button.addEventListener('click', (e) => {
                const id = button.getAttribute('data-id') || '';
                const action = button.getAttribute('data-action') as 'primary' | 'secondary';
                handleButtonClick(e as MouseEvent, id, action, data);
            });
        });

        return container;
    };

    const createLocationMarker = () => {
        const el = document.createElement('div');
        el.className = 'marker';
        el.innerHTML = `
         <svg fill="#000000" width="800px" height="800px" viewBox="0 0 36 36" version="1.1"  preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>house-solid</title>
    <path class="clr-i-solid clr-i-solid-path-1" d="M33,19a1,1,0,0,1-.71-.29L18,4.41,3.71,18.71a1,1,0,0,1-1.41-1.41l15-15a1,1,0,0,1,1.41,0l15,15A1,1,0,0,1,33,19Z"></path><path class="clr-i-solid clr-i-solid-path-2" d="M18,7.79,6,19.83V32a2,2,0,0,0,2,2h7V24h6V34h7a2,2,0,0,0,2-2V19.76Z"></path>
    <rect x="0" y="0" width="36" height="36" fill-opacity="0"/>
</svg>
        `;
        return el;
    };

    const createVehicleMarker = () => {
        const el = document.createElement('div');
        el.className = 'marker';
        el.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
                <path d="M240,104H229.2L201.42,41.5A16,16,0,0,0,186.8,32H69.2a16,16,0,0,0-14.62,9.5L26.8,104H16a8,8,0,0,0,0,16h8v80a16,16,0,0,0,16,16H64a16,16,0,0,0,16-16V184h96v16a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V120h8a8,8,0,0,0,0-16ZM69.2,48H186.8l24.89,56H44.31ZM64,200H40V184H64Zm128,0V184h24v16Zm24-32H40V120H216Z"></path>
            </svg>
        `;
        return el;
    };

    // Initialize map
    useEffect(() => {
        if (!mapContainer.current || map.current) return;

        mapboxgl.accessToken = 'pk.eyJ1IjoibWFya2tpZWUiLCJhIjoiY2t1eDY5YXYyMmMwbTJ1cXY5c3hnaXkyaiJ9.xTAMNWP3gouVEQNTaX5EqQ';

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [5.2913, 52.1326], // Netherlands center
            zoom: 7
        });

        map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

        fetchSubscriptions();
        const intervalId = setInterval(fetchSubscriptions, 5 * 60 * 1000);

        return () => {
            if (map.current) {
                map.current.remove();
                map.current = null;
            }
            clearInterval(intervalId);
        };
    }, []);

    // Handle markers
    useEffect(() => {
        if (!map.current) return;

        const db = getDatabase();

        // Listen for subscription updates
        const subscriptionsRef = ref(db, 'subscriptions');
        const handleSubscriptions = (snapshot: any) => {
            if (!snapshot.exists()) return;

            const subscriptions: Subscription[] = snapshot.val();

            subscriptions.forEach(subscription => {
                const markerId = `subscription-${subscription.id}`;

                if (!markersRef.current[markerId] && map.current) {
                    const popup = new mapboxgl.Popup({ offset: 25 })
                        .setDOMContent(createPopupContent('location', subscription));

                    markersRef.current[markerId] = new mapboxgl.Marker(createLocationMarker())
                        .setLngLat([parseFloat(subscription.long), parseFloat(subscription.lat)])
                        .setPopup(popup)
                        .addTo(map.current);
                }
            });
        };

        // Listen for vehicle location updates
        const locationsRef = ref(db, 'locations');
        const handleLocations = (snapshot: any) => {
            if (!snapshot.exists()) return;

            const locations = snapshot.val();
            Object.entries(locations).forEach(([userId, location]: [string, any]) => {
                const markerId = `vehicle-${userId}`;
                const { latitude, longitude, speed = 0, name = 'Onbekende gebruiker' } = location;
                const lastUpdateTime = new Date(location.lastUpdate).toLocaleTimeString();

                const vehicleData = {
                    userId,
                    name,
                    speed,
                    lastUpdateTime,
                };

                if (!markersRef.current[markerId] && map.current) {
                    const popup = new mapboxgl.Popup({ offset: 25 })
                        .setDOMContent(createPopupContent('vehicle', vehicleData));

                    markersRef.current[markerId] = new mapboxgl.Marker(createVehicleMarker())
                        .setLngLat([longitude, latitude])
                        .setPopup(popup)
                        .addTo(map.current);
                } else if (markersRef.current[markerId]) {
                    markersRef.current[markerId].setLngLat([longitude, latitude]);
                    const popup = markersRef.current[markerId].getPopup();
                    popup?.setDOMContent(createPopupContent('vehicle', vehicleData));
                }
            });
        };

        onValue(subscriptionsRef, handleSubscriptions);
        onValue(locationsRef, handleLocations);
        setLoading(false);

        return () => {
            off(subscriptionsRef);
            off(locationsRef);
            Object.values(markersRef.current).forEach(marker => marker.remove());
            markersRef.current = {};
        };
    }, []);

    return (
        <div className="w-full h-screen bg-gray-100 p-4">
            <div className="w-full h-full relative rounded-lg overflow-hidden border border-gray-200">
                <div
                    ref={mapContainer}
                    className="absolute inset-0"
                    style={{ position: 'absolute', top: 0, bottom: 0, width: '100%' }}
                />
            </div>
        </div>
    );
};

export default MapComponent;